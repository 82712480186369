module.exports = function(module2) {
  if (!module2.webpackPolyfill) {
    module2.deprecate = function() {
    };
    module2.paths = [];
    if (!module2.children)
      module2.children = [];
    Object.defineProperty(module2, "loaded", {
      enumerable: true,
      get: function() {
        return module2.l;
      }
    });
    Object.defineProperty(module2, "id", {
      enumerable: true,
      get: function() {
        return module2.i;
      }
    });
    module2.webpackPolyfill = 1;
  }
  return module2;
};
